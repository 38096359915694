import React, {Component} from 'react'
import Header from "../partials/header"
import Footer from "../partials/footer"
import axios from "axios/index";
import {format} from 'date-fns';
import tick from "../images/tick.png";
import cross from "../images/cross.jpg";
import {isBrandLoggedin, logout} from "../services/auth";
import {navigate} from "gatsby";

if (typeof window != 'undefined') {
    var QrReader = require('modern-react-qr-reader')
}

class scanqr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: 'No result',
            qr: false,
            error: "",
            loaderdd: true,
            hide: true,
            errormsg: ""
        }
        this.handleError = this.handleError.bind(this);
        this.handleScan = this.handleScan.bind(this);
    }

    componentDidMount = () => {
        if (!isBrandLoggedin()) {
            if (typeof window !== "undefined") {
                navigate('/brand-login')
            }
        } else {
            this.setState({loaderdd: false})
        }
    }

    /*Scan QR*/

    handleScan = async (data) => {
        let aruzyuser1 = {};
        if (typeof window !== "undefined") {
            const isBrowser = typeof window !== "undefined";
            const aruzyuser = isBrowser ? localStorage.getItem('aruzyuser') : {};
            aruzyuser1 = isBrowser && localStorage.getItem('aruzyuser') !== null ? JSON.parse(aruzyuser) : {};
        }

        if (data) {
            this.setState({hide: false})
            this.state.result = data;
            let datares = this.state.result.split(" ");
            await axios.post(`${process.env.API_URL}/getvoucherdetail`, {
                "voucherid": datares[1],
                "userid": datares[0],
                "brandid": aruzyuser1.id
            },).then((res) => {
                if (res.data.error === false) {
                    this.setState({qr: false})
                    this.setState({error: false})
                    this.setState({errormsg: ''})
                    this.setState({hide: false})
                    this.setState({qr: res.data.data})
                    this.setState({error: false});
                } else {
                    this.setState({qr: false})
                    this.setState({error: true})
                    this.setState({errormsg: res.data.msg})
                }
            }).catch((error) => {
                this.setState({qr: false})
                this.setState({error: true})
                this.setState({errormsg: "Something went wrong try again"})
            });
            this.setState({result: data})
        }
    }
    changedateFormat = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy hh:mma');
    }
    readagain = () => {
        this.setState({hide: true})
        this.setState({qr: false})
    }
    handleError = err => {
        console.error(err)
    }

    render() {
        return (
            <main>
                <Header/>
                <div>
                    {this.state.loaderdd && <div>loaded...<br/><br/><br/><br/><br/></div>}
                    {!this.state.loaderdd && <div className="container">
                        <div className="row mt-3">
                                 <div className="col-md-6 text-left  " >
                                    <span className="mb-2 cursor-pointer" onClick={event => {
                                        event.preventDefault();
                                        navigate(`/brand-home`)
                                    }} style={{fontSize: '20px'}}>
                                        <span className="fas fa-arrow-left" style={{fontSize: '18px', color: '#ff3c7c'}}></span> Back
                                    </span>
                                </div>
                                <div className="col-md-6 text-right " >
                                    <span className="mb-2 cursor-pointer" onClick={event => {
                                        event.preventDefault();
                                        logout(() => navigate(`/brand-login`))
                                    }} style={{fontSize: '20px'}}>
                                        <span className="fas fa-sign-out-alt" style={{
                                            fontSize: '18px',
                                            color: '#ff3c7c'
                                        }}></span> Logout
                                    </span>
                            </div>
                            <h3 className="text-center">Scan QR Code</h3>
                        </div>
                        <div className="offset-md-4 scanqr col-md-4 ">
                            <div className="changeqrcode">
                                {
                                    (typeof window !== "undefined" && this.state.hide) &&
                                    <QrReader
                                        delay={300}
                                        facingMode={"environment"}
                                        onError={this.handleError}
                                        onScan={this.handleScan}
                                        style={{width: '100%'}}/>
                                }
                            </div>
                        </div>
                        <div className="offset-md-4 scanqr mt-4 col-md-4">
                            {this.state.qr &&
                                <div className="text-center">
                                    <img className="tickbox" src={tick}/><br/>
                                    <label><b>Voucher: </b>{this.state.qr.voucher.offer}</label><br/>
                                    <label><b>Customer: </b>{this.state.qr.user.fname}</label><br/>
                                    <label><b>Created At: </b>{this.changedateFormat(this.state.qr.created_at)}</label><br/>
                                    <div className="text-center searchagain">
                                        <button className="btn btn-primary" onClick={() => this.readagain()}>Scan again</button>
                                    </div>
                                </div>}
                            {this.state.error &&
                                <div className="text-center">
                                    <img className="tickbox" src={cross}/><br/>
                                    <div className="alert alert-danger">{this.state.errormsg}</div>
                                    <div className="text-center searchagain">
                                        <button className="btn btn-primary" onClick={() => this.readagain()}>Scan again</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <br/><br/>
                    </div>
                    }
                </div>
                <Footer/>
            </main>
        )
    }
}

export default scanqr;